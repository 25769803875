import { render, staticRenderFns } from "./Upload.vue?vue&type=template&id=c82af9ae&scoped=true&"
import script from "../assets/js/upload.js?vue&type=script&lang=ts&"
export * from "../assets/js/upload.js?vue&type=script&lang=ts&"
import style0 from "../assets/styles/main.less?vue&type=style&index=0&id=c82af9ae&lang=less&scoped=true&"
import style1 from "../assets/styles/upload.less?vue&type=style&index=1&id=c82af9ae&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c82af9ae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VCardActions,VCheckbox,VChip,VCol,VContainer,VFileInput,VForm,VIcon,VProgressLinear,VRow,VTabItem,VTabsItems,VTextField,VTextarea})
